import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/pageHero"

import "../style/page/contact.scss"

const ContactPage = () => (
  <Layout bgColor="#d79c77">
    <SEO title="Contact" />
    <Hero>
      <p>
        <span>One More Step.</span> Fill Up the info, and we'll contact you as
        soon as possible for further arrangement.
      </p>
    </Hero>
    <section className="form">
      <div className="form-wrapper">
        <iframe
          title="google form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdqQHRze5S97gVSn-6X_Lf0vNM4qLcVLwG7F6EIk6SsZ8UEAQ/viewform?embedded=true"
          width="100%"
          height="1969"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading...
        </iframe>
      </div>
    </section>
  </Layout>
)

export default ContactPage
